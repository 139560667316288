import React, { useEffect, useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import Joi from "joi";
import {
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Alert,
  Snackbar,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import HowToRegIcon from "@mui/icons-material/HowToReg";

import { getCookie } from "../../shared/getCookie";
import API_REQ from "../../shared/API_REQ";

export default function Register() {
  const navigate = useNavigate();
  const formEl = useRef();

  const [isLoggedIn, setIsLoggedIn] = useState(getCookie("token"));
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [values, setValues] = useState({
    fname: "",
    lname: "",
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});

  const registerSchema = Joi.object({
    fname: Joi.string().required(),
    lname: Joi.string().required(),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .custom((value, helpers) => {
        if (
          value.endsWith("@lagentx.com") ||
          value.endsWith("@techmyteam.com")
        ) {
          return value;
        } else {
          return helpers.message(
            "The register email must be lagentx.com or techmyteam.com"
          );
        }
      }),
    password: Joi.string().required().min(5),
  });

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    API_REQ.post("/user", values)
      .then(({ data }) => {
        setIsLoggedIn(true);
        setIsLoading(false);
        setIsError(false);
      })
      .catch((err) => {
        setIsLoggedIn(false);
        setIsLoading(false);
        setIsError({
          message: err?.response?.data,
        });
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });

    const result = registerSchema.validate({...values, [name]: value});
    if (result.error) {
      const errors = {};
      for (let item of result.error.details) {
        errors[item.path[0]] = item.message;
      }
      setErrors(errors);
    } else {
      setErrors({});
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, []);

  return (
    <div className="page page-login">
      <Grid container rowSpacing={4} style={{ maxWidth: "700px" }}>
        <Grid item xs={12} align="center">
          <Link to="/">
            <img
              className="logo"
              src="/assets/images/icon-only-white.png"
              alt="Logo"
            />
          </Link>
        </Grid>
        <Grid my={4}>
          <legend style={{ fontSize: 26, fontWeight: 700 }}>Register:</legend>
        </Grid>

        <form
          className="form form-login"
          ref={formEl}
          onSubmit={submitHandler}
          onChange={handleChange}
        >
          <Grid container rowSpacing={4} columnSpacing={4}>
            <Grid container item xs={12} rowSpacing={4} columnSpacing={4}>
              <Grid item xs={12} md={6}>
                <FormControl
                  style={{
                    width: "100%",
                    padding: 0,
                  }}
                >
                  <TextField
                    label="First Name"
                    id="fname"
                    name="fname"
                    autoComplete="off"
                    aria-describedby="fname"
                    autoFocus
                    required
                    value={values.fname}
                    error={errors["fname"] ? true : false}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  style={{
                    width: "100%",
                    padding: 0,
                  }}
                >
                  <TextField
                    label="Last Name"
                    id="lname"
                    name="lname"
                    autoComplete="off"
                    aria-describedby="lname"
                    autoFocus
                    required
                    value={values.lname}
                    error={errors["lname"] ? true : false}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={6}>
              <FormControl
                style={{
                  width: "100%",
                  padding: 0,
                }}
              >
                <TextField
                  label="Email"
                  id="email"
                  name="email"
                  autoComplete="off"
                  aria-describedby="email"
                  autoFocus
                  required
                  value={values.email}
                  type="email"
                  error={errors["email"] ? true : false}
                />
                {errors["email"] && errors["email"] && (
                  <FormHelperText style={{
                    color: 'red'
                  }}>{errors["email"]}</FormHelperText>
                )}
                <FormHelperText id="email-helper-text">
                  please use TechMyTeam or L'agentX email.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid container item xs={12} md={6}>
              <FormControl
                style={{
                  width: "100%",
                  padding: 0,
                }}
              >
                <TextField
                  label="Password"
                  inputProps={{
                    autoComplete: "new-password",
                  }}
                  id="password"
                  name="password"
                  autoComplete="off"
                  value={values.password}
                  aria-describedby="password"
                  required
                  type="password"
                  error={errors["password"] ? true : false}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Snackbar
                autoHideDuration={5000}
                open={isError ? true : false}
                onClose={() => {
                  setIsError(false);
                }}
              >
                <Alert sx={{ mb: 2 }} severity="error">
                  {isError.message || "Error"}
                </Alert>
              </Snackbar>
              <Snackbar
                autoHideDuration={3000}
                key="up"
                open={isLoggedIn ? true : false}
                onClose={() => {
                  navigate("/user/login");
                }}
              >
                <Alert sx={{ mb: 2 }} severity="success">
                  Successfully Registered
                </Alert>
              </Snackbar>
              <LoadingButton
                loading={isLoading}
                type="submit"
                variant="contained"
              >
                Register{" "}
                <HowToRegIcon fontSize="small" style={{ margin: "0 8px" }} />
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
        <Grid item xs={12}>
          <Divider />
          <p>
            Already have an account?{" "}
            <Link className="link" to="/user/login">
              Sign in
            </Link>
          </p>
        </Grid>
      </Grid>
    </div>
  );
}
