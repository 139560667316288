import React, { useEffect, useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import Joi from "joi";
import {
  FormControl,
  Grid,
  TextField,
  Alert,
  Snackbar,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import LoginIcon from "@mui/icons-material/Login";

import { getCookie } from "../../shared/getCookie";
import API_REQ from "../../shared/API_REQ";

export default function Login() {
  const navigate = useNavigate();
  const formEl = useRef();

  const [isLoggedIn, setIsLoggedIn] = useState(getCookie("token"));
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false); const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});

  const loginSchema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
    password: Joi.string().required().min(5),
  });

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    API_REQ.post("/user/login", values)
      .then(({ data }) => {
        const cookie = `token=${data.token}; expires=${new Date(
          data.options.expires
        ).toUTCString()}; path=${data.options.path || "/"};`;
        document.cookie = cookie;
        setIsLoggedIn(true);
        setIsLoading(false);
        setIsError(false);
        localStorage.setItem('user', JSON.stringify(data.user));
      })
      .catch((err) => {
        setIsLoggedIn(false);
        setIsLoading(false);
        setIsError({
          message: err?.response?.data,
        });
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });

    const result = loginSchema.validate(values);
    if (result.error) {
      const errors = {};
      for (let item of result.error.details) {
        errors[item.path[0]] = item.message;
      }
      setErrors(errors);
    } else {
      setErrors({});
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  });

  return (
    <div className="page page-login">
      <Grid container rowSpacing={4} style={{ maxWidth: "700px" }}>
        <Grid item xs={12} align="center">
          <Link to="/">
            <img
              className="logo"
              src="/assets/images/icon-only-white.png"
              alt="Logo"
            />
          </Link>
        </Grid>
        <Grid item xs={12}>
          <legend style={{ fontSize: 26, fontWeight: 700 }}>Login:</legend>
        </Grid>

        <Grid item xs={12} rowSpacing={4} columnSpacing={4}>
          <form
            className="form form-login"
            ref={formEl}
            onSubmit={submitHandler}
            onChange={handleChange}
          >
            <Grid container columnSpacing={4} rowSpacing={4} >
              <Grid item xs={12} md={6}>
                <FormControl
                  style={{
                    width: "100%",
                  }}
                >
                  <TextField
                    label="Email"
                    id="email"
                    name="email"
                    autoComplete="off"
                    aria-describedby="email"
                    autoFocus
                    required
                    value={values.email}
                    type="email"
                    error={errors["email"] ? true : false}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  style={{
                    width: "100%",
                  }}
                >
                  <TextField
                    label="Password"
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    id="password"
                    name="password"
                    autoComplete="off"
                    value={values.password}
                    aria-describedby="password"
                    required
                    type="password"
                    error={errors["password"] ? true : false}
                  />
                </FormControl>
              </Grid>
              <Grid item align="right" sm={12}>
                <Link to="/user/forgot-password">Forgot Password?</Link>
              </Grid>
              <Grid item xs={12}>
                <Snackbar
                  autoHideDuration={5000}
                  open={isError ? true : false}
                  onClose={() => {
                    setIsError(false);
                  }}
                >
                  <Alert sx={{ mb: 2 }} severity="error">
                    {isError.message || "Error"}
                  </Alert>
                </Snackbar>
                <Snackbar
                  autoHideDuration={3000}
                  open={isLoggedIn ? true : false}
                  onClose={() => {
                    navigate("/");
                  }}
                >
                  <Alert sx={{ mb: 2 }} severity="success">
                    Succussfully Logged in
                  </Alert>
                </Snackbar>
                <LoadingButton
                  loading={isLoading}
                  type="submit"
                  variant="contained"
                >
                  Log in{" "}
                  <LoginIcon fontSize="small" style={{ margin: "0 8px" }} />
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <p>
            Need an account?{" "}
            <Link className="link" to="/user/register">
              Sign up
            </Link>
          </p>
        </Grid>
      </Grid>
    </div>
  );
}
