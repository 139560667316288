import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { getCookie } from "../shared/getCookie";
import Header from "../Components/Header";
import CodeEditor from "@uiw/react-textarea-code-editor";
import {
  Grid,
  Button,
  IconButton,
  Box,
  Typography,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Snackbar,
  Alert,
  List,
  ListItem,
  Link,
  Input,
  Slide
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import API_REQ from "../shared/API_REQ";
import CodeIcon from "@mui/icons-material/Code";

export default function Home() {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(getCookie("token"));

  const formRef = useRef();

  const [options, setOptions] = useState({
    encode: "on",
    shtml: "on",
    sphp: "on",
  });

  const [optionsError, setOptionsError] = useState({
    encode: null,
    shtml: null,
    sphp: null,
  });

  const [pcode, setPCode] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileNameError, setFileNameError] = useState(false);

  const [fileDetails, setFileDetails] = useState(null);

  const [encode, setEncode] = useState({ data: null, error: null });
  const [isCopied, setIsCopyied] = useState(null);
  const [isCopying, setIsCopying] = useState(false);
  const [syntaxHTML, setSyntaxHTML] = useState({ data: null, error: null });
  const [syntaxPHP, setSyntaxPHP] = useState({ data: null, error: null });

  const [isSuccess, setIsSeccuss] = useState(null);
  const [isProcessed, setIsProcessed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/user/login");
    }
  }, [navigate, isLoggedIn]);

  const onlyUpload = true;

  useEffect(() => {
    if (!isProcessed) return;
    let polluted = false;
    for (let key in options) {
      switch (key) {
        case "encode":
          if (encode.error) {
            setOptionsError({ ...optionsError, encode: true });
            polluted = true;
          }
          break;
        case "shtml":
          if (syntaxHTML?.data?.data?.length > 0) {
            setOptionsError({ ...optionsError, shtml: true });
            polluted = true;
          }
          break;
        case "sphp":
          if (syntaxPHP.error) {
            setOptionsError({ ...optionsError, sphp: true });
            polluted = true;
          }
          break;
        default:
          return;
      }
    }
  }, [isProcessed]);

  useEffect(() => {
    if (!isProcessed || isSuccess !== true) return;

    const token = Cookies.get("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const sendSuccess = async () => {
      setIsLoading(true);
      await API_REQ.post(
        "/logChecks",
        {
          ...fileDetails,
          htmlData: syntaxHTML?.data?.data,
          phpData: syntaxPHP.error,
        },
        config
      )
        .then(({ data }) => {
          setIsLoading(false);
        })
        .catch(({ response }) => {
          setIsLoading(false);
        });
    };
    sendSuccess();
  }, [isSuccess]);

  const checkEncoding = async (file, config) => {
    await API_REQ.post("/encoding", file, config)
      .then(({ data }) => {
        setEncode({ data: data?.data, error: null });
        setFileDetails({
          ...fileDetails,
          fileName: data.name,
        });
      })
      .catch(({ response }) => {
        setEncode({ data: null, error: true });
        setIsError(response || { data: "An error occurs" });
      });
  };

  const checkHTMLSyntax = async (file, config) => {
    await API_REQ.post("/syntaxHTML", file, config)
      .then(({ data }) => {
        // data null is valid HTML
        setSyntaxHTML({ data: data, error: null });
        setFileDetails({
          ...fileDetails,
          fileName: data.name,
          date: data.time,
        });
      })
      .catch(({ response }) => {
        setSyntaxHTML({ data: null, error: true });
        setIsError(response || { data: "An error occurs" });
      });
  };

  const checkPHPSytnax = async (file, config) => {
    await API_REQ.post("/syntaxPHP", file, config)
      .then(({ data }) => {
        // data {isValidPHP : true processPHP : null}
        setSyntaxPHP(data);
        setFileDetails({
          ...fileDetails,
          fileName: data.name,
          date: data.time,
        });
      })
      .catch(({ response }) => {
        // PHP Errors handled here
        setSyntaxPHP({ data: null, error: true });
        setIsError(response || { data: "An error occurs" });
      });
  };

  const processFile = async (file) => {
    const token = Cookies.get("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    setIsLoading(true);

    for (let key in options) {
      const params = [file, config];
      switch (key) {
        case "encode":
          await checkEncoding(...params);
          break;
        case "shtml":
          await checkHTMLSyntax(...params);
          break;
        case "sphp":
          await checkPHPSytnax(...params);
          break;
        default:
          return;
      }
    }

    setIsProcessed(true);
    setIsSeccuss(true);
  };

  const onChange = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    processFile(formData);
  };

  const submitCode = () => {
    if (fileName.length < 4) {
      setFileNameError(true);
      return;
    }
    processFile({ file: pcode, name: fileName });
  };

  const optionsOnChange = (form) => {
    var formData = new FormData(form);
    setOptions(Object.fromEntries(formData));
  };

  return !isLoggedIn ? null : (
    <div className="page page-home">
      <Header />
      {isLoading ? (
        <CircularProgress
          style={{
            position: "absolute",
            top: "calc(50% - 25px)",
            right: "calc(50% - 25px)",
          }}
        />
      ) : isProcessed ? (
        <Grid className="result" container rowSpacing={6}>
          <Grid item my={4} md={12} xs={12}>
            <Button
              variant="contained"
              onClick={() => {
                setIsProcessed(false);
                setIsSeccuss(null);
                setOptions({ encode: "on", sphp: "on", shtml: "on" });
                setFileDetails(null);
              }}
            >
              <ArrowBackIcon /> Back
            </Button>
          </Grid>
          {options.encode && (
            <Grid container item md={12} xs={12} rowSpacing={0}>
              <Grid item md={12} xs={12}>
                <Typography
                  variant="h5"
                  color={encode.error ? "red" : "green"}
                  alignItems="center"
                  display="flex"
                  mb={2}
                >
                  {!encode.error ? <CheckCircleIcon /> : <ErrorIcon />}
                  Encoding
                </Typography>
              </Grid>
              {!encode.error && encode.data && (
                <Grid item md={12} xs={12}>
                  <CodeEditor
                    value={encode.data}
                    language="html"
                    onKeyPress={(e) => e.preventDefault()}
                    readOnly
                    padding={15}
                    style={{
                      height: "300px",
                      fontSize: 12,
                      backgroundColor: "#161616",
                      overflow: "scroll",
                      fontFamily:
                        "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                    }}
                  />
                  {navigator.clipboard && (
                    <LoadingButton
                      style={{
                        margin: "14px",
                        padding: "10px",
                      }}
                      loading={isCopying}
                      loadingIndicator="Copying"
                      variant="contained"
                      onClick={() => {
                        setIsCopying(true);
                        navigator.clipboard.writeText(encode.data).then(
                          function () {
                            console.log(
                              "Async: Copying to clipboard was successful!"
                            );
                            setIsCopyied(true);
                            setIsCopying(false);
                          },
                          function (err) {
                            console.error("Async: Could not copy text: ", err);
                            setIsCopyied(false);
                            setIsCopying(false);
                          }
                        );
                      }}
                    >
                      Copy
                    </LoadingButton>
                  )}
                </Grid>
              )}
              {encode.error && (
                <Alert sx={{ my: 2 }} severity="error">
                  Encoding Error
                </Alert>
              )}
            </Grid>
          )}

          {options.shtml && (
            <Grid
              className="shtml-result"
              container
              item
              xs={12}
              rowSpacing={2}
            >
              <Grid item md={12} xs={12}>
                <Typography
                  variant="h5"
                  color={
                    syntaxHTML.error || syntaxHTML?.data?.data?.length > 0
                      ? "red"
                      : "green"
                  }
                  alignItems="center"
                  display="flex"
                  mb={1}
                >
                  {syntaxHTML.data && syntaxHTML?.data?.data?.length > 0 ? (
                    <ErrorIcon />
                  ) : (
                    <CheckCircleIcon />
                  )}
                  Sytnax HTML
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {syntaxHTML.data && syntaxHTML?.data?.data?.length > 0 && (
                  <div>
                    <List
                      style={{
                        color: "red",
                      }}
                    >
                      {syntaxHTML.data.data.map((item, i) => (
                        <ListItem key={i}>
                          <Typography mr={1}>{i + 1} - </Typography> |
                          <Typography mx={1}> Line {item.line}</Typography> |
                          <code
                            style={{
                              fontSize: 16,
                              margin: "0 12px",
                            }}
                          >
                            {item.ruleId}
                          </code>{" "}
                          |<Typography mx={1}>{item.message}</Typography> |
                          <Link mx={1} href={item.ruleUrl} target="_blank">
                            {" "}
                            For more details{" "}
                          </Link>
                        </ListItem>
                      ))}
                    </List>
                  </div>
                )}
              </Grid>
              {syntaxHTML.error && (
                <Alert sx={{ my: 2 }} severity="error">
                  Sytnax HTML Error
                </Alert>
              )}
            </Grid>
          )}

          {options.sphp && (
            <Grid
              className="sphp-result"
              container
              item
              md={12}
              rowSpacing={2}
              columnSpacing={2}
            >
              <Grid item md={12} xs={12}>
                <Typography
                  variant="h5"
                  color={syntaxPHP.error ? "red" : "green"}
                  alignItems="center"
                  display="flex"
                  mb={1}
                >
                  {!syntaxPHP.error ? <CheckCircleIcon /> : <ErrorIcon />}
                  Sytnax JSP
                </Typography>
              </Grid>
              {syntaxPHP.data && (
                <>
                  {syntaxPHP.error && (
                    <Grid item md={6} xs={12}>
                      <Alert sx={{ my: 2 }} severity="error">
                        {syntaxPHP.error || "Sytnax PHP Error"}
                      </Alert>
                    </Grid>
                  )}
                  <Grid item md={syntaxPHP.error ? 6 : 12} xs={12}>
                    {syntaxPHP.data.length > 0 ? (
                      <List
                        style={{
                          color: "green",
                        }}
                      >
                        <Typography>Treated JSP Blocks:</Typography>
                        {syntaxPHP.data.map((item, i) => (
                          <ListItem key={i}>
                            <Typography mr={1}>{i + 1} - </Typography>
                            <code
                              style={{
                                fontSize: 16,
                                margin: "0 12px",
                              }}
                            >
                              {item}
                            </code>{" "}
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <Typography
                        style={{
                          color: "green",
                        }}
                      >
                        No PHP to Check
                      </Typography>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </Grid>
      ) : (
        <>
          <form
            style={{
              width: "100%",
              maxWidth: "700px",
              margin: "auto",
            }}
            ref={formRef}
            onChange={() => optionsOnChange(formRef.current)}
          >
            <FormGroup display="flex">
              <Grid container>
                <Grid item sm={3} xs={12}>
                  <FormControlLabel
                    name="encode"
                    control={
                      <Checkbox checked={options?.encode ? true : false} />
                    }
                    label="Encoding"
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <FormControlLabel
                    name="shtml"
                    control={
                      <Checkbox checked={options?.shtml ? true : false} />
                    }
                    label="HTML Syntax"
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <FormControlLabel
                    name="sphp"
                    control={
                      <Checkbox checked={options?.sphp ? true : false} />
                    }
                    label="JSP Syntax"
                  />
                </Grid>
                <Grid item sm={3}>
                  <Button
                    className="btn btn-selectAll"
                    variant="contained"
                    onClick={() =>
                      setOptions({ encode: true, shtml: true, sphp: true })
                    }
                  >
                    {" "}
                    Select All{" "}
                  </Button>
                </Grid>
              </Grid>
            </FormGroup>
          </form>
          <Divider
            style={{
              display: "block",
              margin: "10px 0 20px",
            }}
          />
          <Divider
            style={{
              display: "block",
              margin: "10px 0 20px",
            }}
          />
          <Grid container maxWidth="lg" m="auto" rowSpacing={4}>
            {!onlyUpload && (
              <>
                <Grid item xs={12} md={pcode.length === 0 ? 5 : 12}>
                  <CodeEditor
                    value={pcode}
                    language="html"
                    placeholder="Please paste your code here."
                    onChange={(evn) => setPCode(evn.target.value)}
                    onKeyPress={(e) => e.preventDefault()}
                    padding={15}
                    style={{
                      height: "300px",
                      fontSize: 12,
                      backgroundColor: "#161616",
                      overflow: "scroll",
                      fontFamily:
                        "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                    }}
                  />
                  {pcode.length > 0 && (
                    <div
                      style={{
                        margin: "20px 0",
                      }}
                    >
                      <Input
                        style={{
                          width: "100%",
                          maxWidth: 400,
                        }}
                        autoFocus
                        name="fileName"
                        placeholder="File name. Ex: FR.NewsLetter"
                        required
                        type="string"
                        error={fileName.length < 4 ? true : false}
                        onChange={(e) => setFileName(e.target.value)}
                        value={fileName}
                      />
                      <br />
                      <Button
                        variant="contained"
                        style={{ margin: "20px 0" }}
                        onClick={submitCode}
                      >
                        Submit
                        <SendIcon
                          style={{
                            marginLeft: 10,
                          }}
                          fontSize="small"
                        />
                      </Button>
                    </div>
                  )}
                </Grid>
                {pcode.length === 0 && (
                  <Grid
                    item
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    md={2}
                    xs={12}
                  >
                    <Typography
                      style={{
                        fontSize: 22,
                        fontWeight: "bold",
                      }}
                    >
                      Or
                    </Typography>
                  </Grid>
                )}
              </>
            )}

            {pcode.length === 0 && (
              <Grid
                item
                xs={12}
                md={onlyUpload ? 12 : 5}
                alignItems="center"
                justifyContent="center"
                display="flex"
              >
                <Box component="span" sx={{ p: 2, border: "1px dashed grey" }}>
                  <Button variant="contained" component="label">
                    Upload
                    <form onChange={onChange}>
                      <input hidden accept=".html" type="file" />
                    </form>
                  </Button>
                  <IconButton
                    color="primary"
                    aria-label="upload code"
                    component="label"
                  >
                    <input hidden type="file" />
                    <CodeIcon />
                  </IconButton>
                </Box>
              </Grid>
            )}
          </Grid>
        </>
      )}

      <Snackbar
        autoHideDuration={5000}
        open={fileNameError ? true : false}
        onClose={() => {
          setFileNameError(false);
        }}
      >
        <Alert sx={{ mb: 2 }} severity="error">
          File name error! please fill out the file name field.
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={5000}
        open={isError ? true : false}
        onClose={() => {
          setIsError(false);
        }}
      >
        <Alert sx={{ mb: 2 }} severity="error">
          {isError.data || "Error"}
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={8000}
        open={isCopied ? true : false}
        direction="top"
        TransitionComponent={(props) => <Slide  {...props} direction="top"/>}
        onClose={() => {
          setIsCopyied(false)
        }}
      >
        <Alert sx={{ mb: 2 }} severity="info">
          The encoded code is copied successfully
        </Alert>
      </Snackbar>
    </div>
  );
}
