import React, { useEffect, useState, useRef } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import Joi from "joi";
import {
  FormControl,
  Grid,
  TextField,
  Alert,
  Snackbar,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import HowToRegIcon from "@mui/icons-material/HowToReg";

import { getCookie } from "../../shared/getCookie";
import API_REQ from "../../shared/API_REQ";

export default function Reset() {
  const navigate = useNavigate();
  const formEl = useRef();

  let {token} = useParams();

  const [isReset, setIsReset] = useState(getCookie("token"));
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [values, setValues] = useState({
    password: "",
    cpassword: "",
  });
  const [errors, setErrors] = useState({});

  const resetSchema = Joi.object({
    password: Joi.string().min(5).required(),
    cpassword: Joi.string().min(5).required(),
  });

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    API_REQ.post(`/user/resetpassword/${token}`, values)
      .then(({ data }) => {
        setIsReset(true);
        setIsLoading(false);
        setIsError(false);
      })
      .catch((err) => {
        setIsReset(false);
        setIsLoading(false);
        setIsError({
          message: err?.response?.data,
        });
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });

    const result = resetSchema.validate(values);
    if (result.error) {
      const errors = {};
      for (let item of result.error.details) {
        errors[item.path[0]] = item.message;
      }
      setErrors(errors);
    } else {
      setErrors({});
    }
  };

  useEffect(() => {
    if (isReset) {
      navigate("/user/login");
    }
  }, []);

  return (
    <div className="page page-reset">
      <Grid container rowSpacing={6} style={{ maxWidth: "700px" }}>
        <Grid item xs={12} align="center">
          <Link to="/">
            <img
              className="logo"
              src="/assets/images/icon-only-white.png"
              alt="Logo"
            />
          </Link>
        </Grid>
        <Grid item align="center">
          <legend style={{ fontSize: 26, fontWeight: 700 }}>New Password:</legend>
        </Grid>
        <Grid item container sm={12} rowSpacing={4}>
          <form
            className="form form-reset"
            ref={formEl}
            onSubmit={submitHandler}
            onChange={handleChange}
          >
            <Grid container  rowSpacing={4} columnSpacing={4}>
              <Grid item xs={6}>
                <FormControl
                  style={{
                    width: "100%",
                    padding: 0,
                  }}
                >
                  <TextField
                    label="New Password"
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    id="password"
                    name="password"
                    autoComplete="off"
                    value={values.password}
                    aria-describedby="password"
                    required
                    type="password"
                    error={errors["password"] ? true : false}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  style={{
                    width: "100%",
                    padding: 0,
                  }}
                >
                  <TextField
                    label="Confirm New Password"
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    id="cpassword"
                    name="cpassword"
                    autoComplete="off"
                    value={values.cpassword}
                    aria-describedby="confirm password"
                    required
                    type="password"
                    error={errors["cpassword"] ? true : false}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Snackbar
                  autoHideDuration={5000}
                  open={isError ? true : false}
                  onClose={() => {
                    setIsError(false);
                  }}
                >
                  <Alert sx={{ mb: 2 }} severity="error">
                    {isError.message || "Error"}
                  </Alert>
                </Snackbar>
                <Snackbar
                  autoHideDuration={3000}
                  open={isReset ? true : false}
                  onClose={() => {
                    navigate("/user/login");
                  }}
                >
                  <Alert sx={{ mb: 2 }} severity="success">
                    Successfully Reset
                  </Alert>
                </Snackbar>
                <LoadingButton
                  loading={isLoading}
                  type="submit"
                  variant="contained"
                >
                  Reset{" "}
                  <HowToRegIcon fontSize="small" style={{ margin: "0 8px" }} />
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
}
