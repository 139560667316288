import React, { useEffect, useState, useRef } from "react";
import Header from "../Components/Header";

import {
  Grid,
  CircularProgress
} from "@mui/material";

export default function Log() {
  const tableContainerRef = useRef();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
  }, []);

  return (
    <div className="page page-log">
      <Header />
      {isLoading ? (
        <CircularProgress
          style={{
            position: "absolute",
            top: "calc(50% - 25px)",
            right: "calc(50% - 25px)",
          }}
        />
      ) : (
        <Grid container>
          <Grid item>
            <h1>Coming soon ...</h1>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
