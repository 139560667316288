import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Routes, Route, useNavigate } from "react-router-dom";
import "./App.scss";

import Header from "./Components/Header";
import { userData } from "./Redux/actions/appActions";
import { getCookie } from "./shared/getCookie";

import Home from "./Routes/Home.js";
import Variables from "./Routes/Variables.js";
import Builder from "./Routes/Builder.js";
import Log from "./Routes/Log.js";
import Login from "./Routes/User/Login.js";
import Register from "./Routes/User/Register.js";
import Account from "./Routes/User/Account.js";
import PasswordForgot from "./Routes/User/PasswordForgot";
import PasswordReset from "./Routes/User/PasswordReset";
import UserLayout from "./Routes/User/Layout";

function App({
  state: {
    app: { user },
  },
}) {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const allowedPaths = [
    "/user/login",
    "/user/forgot-password",
    "/user/register",
    "/user/reset-password",
  ];

  useEffect(() => {
    if (
      !getCookie("token") &&
      !allowedPaths.includes(window.location.pathname) &&
      !window.location.pathname.startsWith("/user/reset-password/")
    ) {
      navigate("/user/login");
    }
  }, []);

  if (isLoading) return <h1>Loading</h1>;
  if (isError) return <h1>Error</h1>;

  return (
    <div className="App">
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route index path="/Variables" element={<Variables/>} />
        <Route index path="/builder" element={<Builder />} />
        <Route index path="/log" element={<Log />} />
        <Route path="user" element={<UserLayout />}>
          <Route index element={<Account />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="forgot-password" element={<PasswordForgot />} />
          <Route path="reset-password/:token" element={<PasswordReset />} />
        </Route>
        <Route path="*" element={<h1> Not Found </h1>} />
      </Routes>
    </div>
  );
}

function mapStateToProps(state) {
  return { state };
}

export default connect(mapStateToProps)(App);
