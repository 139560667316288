import React from "react";
import Header from "../Components/Header";

export default function Log() {
  return (
    <div className="page page-variables">
      <Header />
        <iframe
          style={{
            width: "80%",
            height: "80%",
          }}
          title="Variables Sheet"
          src="https://docs.google.com/spreadsheets/d/18Sz1CdXUF0_-AOWFnukAENjUOcdZG4DQ7jlSqAdKxZI/edit?usp=sharing"
        ></iframe>
    </div>
  );
}
