import React, { useEffect, useState, useRef } from "react";
import Header from "../Components/Header";
import Cookies from "js-cookie";
import API_REQ from "../shared/API_REQ";

import {
  Grid,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  Link,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import ErrorIcon from "@mui/icons-material/Error";

export default function Log() {
  const tableContainerRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    tableContainerRef.current.scrollTo(0, 0);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setIsLoading(true);

    const sendSuccess = async () => {
      setIsLoading(true);

      const token = Cookies.get("token");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      await API_REQ.get("/logChecks", config)
        .then(({ data }) => {
          setIsLoading(false);
          setLogs(data.map((d, i) => ({ number: i + 1, ...d })));
        })
        .catch(({ response }) => {
          setIsLoading(false);
        });
    };

    sendSuccess();
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <div className="page page-log">
      <Header />
      {isLoading ? (
        <CircularProgress
          style={{
            position: "absolute",
            top: "calc(50% - 25px)",
            right: "calc(50% - 25px)",
          }}
        />
      ) : logs?.length > 0 ? (
        <Grid container>
          <Grid item xs={12}>
            <TableContainer ref={tableContainerRef} sx={{ maxHeight: 480 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Number</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>File</StyledTableCell>
                    <StyledTableCell>Time</StyledTableCell>
                    <StyledTableCell>HTML Check</StyledTableCell>
                    <StyledTableCell>JSP Check</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logs
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((log, i) => (
                      <StyledTableRow key={i}>
                        <TableCell>{log.number}</TableCell>
                        <TableCell>{log.UserName}</TableCell>
                        <TableCell>{log.UserEmail}</TableCell>
                        <TableCell>{log.fileName}</TableCell>
                        <TableCell>{log.date}</TableCell>
                        <TableCell align="center">
                          <div
                            className="cell-wrapper"
                            style={{
                              position: "relative",
                            }}
                          >
                            {log.htmlData && log.htmlData.length > 0 ? (
                              <ErrorIcon
                                style={{
                                  color: "red",
                                }}
                              />
                            ) : (
                              <CheckCircleIcon
                                style={{
                                  color: "green",
                                }}
                              />
                            )}
                            {log.htmlData && log.htmlData.length > 0 && (
                              <Tooltip
                                title={
                                  log.htmlData && log.htmlData.length > 0 ? (
                                    <List
                                      style={{
                                        fontSize: "12px",
                                        lineHeight: "14px",
                                        maxHeight: 300,
                                        overflow: "scroll",
                                      }}
                                    >
                                      {log.htmlData.map((item, i) => (
                                        <div key={i}>
                                          <ListItem
                                            key={i}
                                            style={{
                                              display: "flex",
                                              flexWrap: "wrap",
                                              flexDirection: "column",
                                            }}
                                          >
                                            <p
                                              style={{
                                                background: "black",
                                                borderRadius: 16,
                                                padding: "8px",
                                              }}
                                            >
                                              {i + 1}
                                            </p>
                                            <p>Line {item.line}</p>
                                            <code
                                              style={{
                                                fontSize: 16,
                                                margin: "0 12px",
                                                color: "black",
                                              }}
                                            >
                                              {item.ruleId}
                                            </code>{" "}
                                            <p>{item.message}</p>
                                            <Link
                                              my={1}
                                              href={item.ruleUrl}
                                              target="_blank"
                                              style={{
                                                color: "black",
                                              }}
                                            >
                                              {" "}
                                              For more details{" "}
                                            </Link>
                                          </ListItem>
                                          <Divider my={3} color="black" />
                                        </div>
                                      ))}
                                    </List>
                                  ) : (
                                    false
                                  )
                                }
                              >
                                <InfoIcon
                                  style={{
                                    position: "absolute",
                                    fontSize: 16,
                                    top: -8,
                                  }}
                                />
                              </Tooltip>
                            )}
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          <div
                            className="cell-wrapper"
                            style={{
                              position: "relative",
                            }}
                          >

                            {log.phpData ? (
                              <ErrorIcon
                                style={{
                                  color: "red",
                                }}
                              />
                            ) : (
                              <CheckCircleIcon
                                style={{
                                  color: "green",
                                }}
                              />
                            )}
                            {log.phpData && (
                              <Tooltip
                                title={<p>{log.phpData}</p>}
                                style={{
                                  position: "absolute",
                                  fontSize: 16,
                                  top: -8,
                                }}
                              >
                                <InfoIcon />
                              </Tooltip>
                            )}
                          </div>
                        </TableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={logs.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      ) : (
        <h1>No logs to show</h1>
      )}
    </div>
  );
}
