import { API_URL } from "../config";
import axios from "axios";

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response.data === "jwt malformed") {
      alert("session is Expired. please Login again!");
      window.location.replace("/login");
    }
    return Promise.reject(err);
  }
);

const API_REQ = {
  get: async (path, config = null) => {
    return await axios
      .get(API_URL + path, config)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  post: async (path, data, config = null) => {
    return await axios
      .post(API_URL + path, data, config)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
};

export default API_REQ;
